<template>
  <div class="ma-10">
    <v-form>
      <v-row>
        <v-col cols="12">
          <base-subtitle 
            subtitle="Présentation du datacenter"
            divider
            class="mb-3"
          />
          <div v-html="datacenterPresentationText" />
          <v-row>
            <v-col 
              cols="3" 
              class="d-flex flex-column justify-end align-center"
            >
              <base-button-icon
                icon="mdi-file-pdf"
                color="red"
                size="65"
                class="mx-auto mb-10"
                @click="datacenterPresentationPdfUrl"
              />
              <span class="text-h3 mt-5 mb-3 pl-3"> Présentation du Datacenter </span>
              <a 
                :href="datacenterPresentationPdfUrl" 
                class="download_link mb-3"
                download
              > 
                Cliquez pour télécharger
              </a>
            </v-col>  
            <v-col 
              cols="6"
              class="d-flex flex-column justify-start pl-16"
            >
              <video 
                ref="datacenterVideo"
                controls="controls"
                width="300"
                class="mt-5 mb-7"
                :src="datacenterPresentationVideoUrl"
                type="video/mp4"
              />
              <span class="text-h3 ml-6"> Vidéo du Datacenter </span>
              <base-button 
                text
                height="0"
                width="300"
                class="d-flex justify-start my-0"
                @click="$refs.datacenterVideo.play()"
              > 
                Cliquez pour visionner
              </base-button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <base-subtitle 
            subtitle="Votre hébergement éligible au datacenter régional mutualisé"
            divider
            class="mb-3"
          />
          <v-row class="mb-3">
            <v-col cols="4">
              <form-fields-label
                label="Rationalisation de vos infrastructures"
              />
            </v-col>
            <v-col cols="4">
              <span class="text-h3"> Densification physique avant migration </span>
              <form-fields-text
                v-model="rationalisation_infrastructure_densification_physique"
                type="number"
                placeholder="Part en pourcentage"
                field-description="Réorganisation physique des baies vers une cible à 70% de remplissage."
                class="mb-1 mt-2"
                input-suffix="%"
                number-decimal
                @blur="saveFormAnswer"
              />
            </v-col>
            <v-col cols="4">
              <span class="text-h3">Changement technologique </span>
              <form-fields-text
                v-model="rationalisation_infrastructure_changement_technologique"
                type="number"
                placeholder="Part en pourcentage"
                field-description="Exemple : virtualisation, hyperconvergence, nouveaux équipements, etc..."
                class="mb-1 mt-2"
                input-suffix="%"
                number-decimal
                @blur="saveFormAnswer"
              />
            </v-col>
          </v-row>
          <p class="text-h3 mt-3 mb-8">
            <!-- Cette partie est en attente de plus d'info - variable à 0 en data -->
            Basé sur les informations indiquées précédemment, nous estimons un besoin initial de {{ entite.nombre_baies_eligibles_apres_rationalisation }} baies et {{ entite.nombre_kw_eligibles_apres_rationalisation }} kW dans le datacenter régional mutualisé.
          </p>
          <base-subtitle 
            subtitle="Vos attentes du datacenter régional mutualisé"
            divider
            class="mb-3"
          />
          <form-fields-multi-select 
            v-model="besoin_tier_niveau_service_attendu"
            field-label="Niveau de service attendu" 
            label="Liste des niveaux"
            field-description="TIER selon Uptime Institute. TIER III est le niveau proposé au datacenter mutualisé de Lognes. Tout équipement des infrastructures techniques est maintenable sans impact sur la charge critique."
            :items="formAnswerEnums.niveau_services"
            class="mb-5 mt-6"
            @change="saveFormAnswer"
          />
          <form-fields-multi-select 
            v-model="type_connectivite_ids"
            field-label="Quel type de connectivité avez-vous besoin au sein du datacenter mutualisé ?" 
            label="Liste des opérateurs"
            :items="typeConnectivites"
            item-text="nom"
            item-value="id"
            field-description="Le datacenter mutualisé propose uniquement un service d'hébergement. La connectivité est à souscrire auprès des opérateurs du marché."
            multiple
            class="mb-5"
            @change="saveFormAnswer"
          />
          <div 
            v-for="item in formAnswer.form_answer_type_connectivites" 
            :key="item.id"
          >
            <form-fields-multi-select 
              v-model="item.debit"
              :field-label="`Quel sont les débits requis pour ${ item.type_connectivite.nom } ?`" 
              label="Liste des débits"
              :items="formAnswerEnums.debits"
              class="mb-3"
              @change="saveFormAnswerTypeConnectivite(item.id)"
            />
          </div>
          <form-fields-multi-select 
            v-model="formAnswer.besoin_operateurs_requi_ids"
            field-label="Opérateurs requis au datacenter mutualisé" 
            multiple
            label="Liste des opérateurs"
            :items="listeOperateurs"
            item-text="nom"
            item-value="id"
            @change="saveFormAnswer"
          />
          <form-fields-radio-group 
            v-model="formAnswer.connaissance_cout_liaisons_datacenter_ok"
            :radio-data-list="choiceList"
            field-label="Connaissez-vous le coût de connectivité au DC cible ?"
            class="mt-6"
            simple-radio-button
            @change="saveFormAnswer"
          />
          <form-fields-text
            v-if="formAnswer.connaissance_cout_liaisons_datacenter_ok"
            v-model="formAnswer.cout_liaisons_datacenter"
            field-label="Coût de connectivité au DC cible"
            placeholder="Coût de connectivité au DC cible"
            type="number"
            number-decimal
            required
            min-max
            class="mt-1"
            @blur="saveFormAnswer"
          />
          <div
            v-else
          >
            <form-fields-multi-select
              v-model="formAnswer.liaison_ids"
              field-label="Quel type de liaison envisagez-vous ?"
              label="Liste des liaisons"
              :items="liaisons"
              item-text="nom"
              item-value="id"
              multiple
              field-description="Indiquer les liaisons"
              class="mb-5"
              @change="saveFormAnswer"
            />
            <form-fields-radio-group 
              v-model="formAnswer.nombre_liaisons"
              :radio-data-list="liaisonNumberChoiceList"
              field-label="Combien de liaisons envisagez-vous ?"
              class="mt-6"
              simple-radio-button
              @change="saveFormAnswer"
            />
          </div>
          <form-fields-text
            v-if="liaisonContainsFon && !formAnswer.connaissance_cout_liaisons_datacenter_ok"
            v-model="formAnswer.longueur_liaison_fon_km"
            field-label="Quelle serait la longueur de la liaison FON ?"
            placeholder="Longueur de la liaison FON"
            type="number"
            number-decimal
            required
            min-max
            class="mt-1"
            @blur="saveFormAnswer"
          />
          <form-fields-multi-select 
            v-model="besoin_service_datacenter_ids"
            field-label="Services pouvant être confiés au sein du datacenter mutualisé" 
            label="Liste des services"
            :items="serviceDatacenters"
            item-text="nom"
            item-value="id"
            multiple
            by-order
            field-description="Indiquer les services pouvant être confiés par ordre de préférence"
            class="mb-5"
            @change="saveFormAnswer"
          />
          <form-fields-textarea
            v-model="besoin_services_souhaite"
            field-label="Services que vous souhaiteriez auprès du datacenter mutualisé" 
            placeholder="Indiquer ici les services que vous recommenderiez de développer par le datacenter mutualisé"
            type="textarea"
            @blur="saveFormAnswer"
          />
          <form-fields-multi-select 
            v-model="besoin_prevision_besoin_services_datacenter_mutualise"
            field-label="A quelle échéance envisageriez-vous de requérir aux services du datacenter ?" 
            label="Années"
            :items="yearList"
            @change="saveFormAnswer"
          />
          <form-fields-textarea
            v-model="commentaire_besoin"
            field-label="Commentaires" 
            placeholder="Commentaires"
            type="textarea"
            class="mt-5"
            @blur="saveFormAnswer"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FormFieldsTextarea from '@/components/FormFieldsTextarea.vue'
import FormFieldsMultiSelect from '@/components/FormFieldsMultiSelect.vue'
import FormFieldsText from '@/components/FormFieldsText.vue'
import FormFieldsRadioGroup from '@/components/FormFieldsRadioGroup.vue'
import BaseSubtitle from '@/components/BaseSubtitle.vue'
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapState, mapMutations } from 'vuex'
import FormFieldsLabel from '@/components/FormFieldsLabel.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseButtonIcon from '@/components/BaseButtonIcon.vue'
import { some } from 'lodash';

export default {
  name: "MutualizedDatacenter",
  components: {
    FormFieldsMultiSelect,
    FormFieldsTextarea,
    FormFieldsText,
    FormFieldsRadioGroup,
    BaseSubtitle,
    FormFieldsLabel,
    BaseButton,
    BaseButtonIcon,
  },
  data () {
    return {
      yearList: [
        '2023',
        '2024',
        '2025',
        '2026',
        '2027',
        '2028',
        '2029',
        '2030',
        'Jamais'
      ],
      choiceList: [
        {
          value: true, 
          label: 'Oui',
        },
        {
          value: false, 
          label: 'Non',
        },
      ],
      liaisonNumberChoiceList: [
        {
          value: 1,
          label: '1 liaison',
        },
        {
          value: 2,
          label: '2 liaisons',
        }
      ],
      nombre_kw_initial: 0,
      nombre_baies_initial: 0,
      nombre_kw_evolutif: 0,
      nombre_baies_evolutif: 0,
    }
  },
  computed: {
    ...mapFields('form', [
      'formAnswer.besoin_pourcentage_part_hebergement_eligible_mutualisation',
      'formAnswer.besoin_pourcentage_reduction_par_rationalisation',
      'formAnswer.besoin_coefficient_puissance_supplementaire_vision_moyen_terme',
      'formAnswer.besoin_coefficient_nombre_baie_supplementaire_vision_moyen_term',
      'formAnswer.besoin_tier_niveau_service_attendu',
      'formAnswer.besoin_debit',
      'formAnswer.besoin_services_souhaite',
      'formAnswer.besoin_prevision_besoin_services_datacenter_mutualise',
      'formAnswer.commentaire_besoin',
      'formAnswer.besoin_hebergement_nombre_baie_eligible_mutualisation',
      'formAnswer.besoin_hebergement_nombre_kw_eligible_mutualisation',
      'formAnswer.type_connectivite_ids',
      'formAnswer.besoin_service_datacenter_ids',
      'formAnswer.besoin_liste_local_it_mutualisable_ids',
      'formAnswer.type_reponse_part_hebergement_mutualisable',
      'formAnswer.rationalisation_infrastructure_densification_physique',
      'formAnswer.rationalisation_infrastructure_changement_technologique',
    ]),
    ...mapState('form', ['typeConnectivites', 'operateurs', 'formAnswer', 'formAnswerEnums', 'serviceDatacenters', 'localIts', 'datacenterPresentationVideoUrl', 'datacenterPresentationPdfUrl', 'entite', 'liaisons']),
    locauxItMutualisables() {
      return this.localIts.filter(localIt => localIt.type_local_it === 'Local informatique / Datacenter / Hébergement en propre' ||
      localIt.type_local_it === 'Housing / Colocation');
    },
    liaisonContainsFon() {
      return some(this.formAnswer.liaisons, element => element.nom === 'FON')
    },
    listeOperateurs() {
      return this.operateurs.map(element => {
        return {
          id: element.id,
          nom: element.nom_autre || element.nom
        }
      })
    },
    datacenterPresentationText() {
      return this.entite.datacenter.details;
    },
  },
  created() {
    this.fetchFormAnswer();
    this.fetchFormAnswersEnums();
    this.fetchTypeConnectivites();
    this.fetchOperateurs();
    this.fetchServiceDatacenters();
    this.setNextView('/strategy/long-terme');
    this.fetchLocalIts();
    this.fetchVideoPresentationDatacenterUrl(4);
    this.fetchPdfPresentationDatacenterUrl(4);
    this.fetchEntite();
    this.fetchLiaisons();
  },
  methods: {
    ...mapMutations('stepper', ['setNextView']),
    ...mapActions('form', [
    'fetchFormAnswer',
    'saveFormAnswer',
    'fetchTypeConnectivites',
    'fetchOperateurs',
    'fetchFormAnswersEnums',
    'saveFormAnswerTypeConnectivite',
    'fetchServiceDatacenters',
    'fetchLocalIts',
    'fetchVideoPresentationDatacenterUrl',
    'fetchPdfPresentationDatacenterUrl',
    'fetchEntite',
    'fetchLiaisons',
  ]),
  play() {
    this.$refs.datacenterVideo.play()
  },
   ...mapMutations('stepper', ['setNextView']),
  },
}
</script>

<style lang="scss">
@import '@/styles/variables';

.form-expansion-panel {
  background-color:  map-deep-get($colors, 'cassiopee-grey', 'lighten-1') !important;
  border-bottom: 1px solid map-deep-get($colors, 'cassiopee-purple', 'base') !important;
}

.v-expansion-panel-header {
  padding: 0px !important;
}

.v-expansion-panel-content__wrap {
  padding: 0px !important;
}

.download_link {
  text-decoration:none;
  color: map-deep-get($colors, 'cassiopee-purple', 'base')!important;
}
</style>